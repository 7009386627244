import React from 'react'
import javascript from '../assets/javascript.png'
import css from '../assets/css.png'
import reactimage from '../assets/react.png'
import graphql from '../assets/graphql.png'
import nextjs from '../assets/nextjs.png'
import node from '../assets/node.png'
import tailwind from '../assets/tailwind.png'
import github from '../assets/github.png'
import html from '../assets/html.png'

function Experience() {
    const techs = [
        {
            id: 1,
            src: html,
            title: 'HTML',
            style: 'shadow-orange-500'
        },
        {
            id: 2,
            src: javascript,
            title: 'JavaScript',
            style: 'shadow-yellow-500'
        },
        {
            id: 3,
            src: css,
            title: 'CSS',
            style: 'shadow-blue-500'
        },
        {
            id: 4,
            src: reactimage,
            title: 'ReactJs',
            style: 'shadow-blue-600'
        },
        {
            id: 5,
            src: graphql,
            title: 'GraphQl',
            style: 'shadow-pink-400'
        },
        {
            id: 6,
            src: nextjs,
            title: 'NextJs',
            style: 'shadow-white'
        },
        {
            id: 7,
            src: node,
            title: 'Node',
            style: 'shadow-green-500'
        },
        {
            id: 8,
            src: tailwind,
            title: 'TailwindCss',
            style: 'shadow-sky-400'
        },
        {
            id: 9,
            src: github,
            title: 'GitHub',
            style: 'shadow-gray-400'
        }
    ]
    return (
        <div name='Experience' className='bg-gradient-to-b from-gray-800 to-black w-full md:h-screen'>
            <div className='max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white '>
                <div>
                    <p className='text-4xl font-bold border-b-4 border-gray-500 p-2 inline'>Experience</p>
                    <p className='py-6'>These are the technologies I've worked with</p>
                </div>
                <div className='w-full grid sm:grid-cols-3 gap-8 text-center py-8 '>

                    {
                        techs.map(({ id, src, title, style }) =>
                            <div key={id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
                                <img src={src} alt=' ' className='w-20 mx-auto' />
                                <p className='mt-4'>{title}</p>
                            </div>

                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Experience